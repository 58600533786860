<template>
  <div>
    <NavBar />
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <div class="dialogWrapper">
      <div class="dialogWindow">
        <div class="searchResult">
          <div class="left">
            <v-col cols="12" lg="12">
              <v-select
                color="#1c7947"
                v-model="subCategoryId"
                :items="subCategories"
                item-text="name"
                item-value="id"
                label="Kategorija"
                persistent-hint
                return-object
                single-line
              ></v-select>
              <v-text-field color="#1c7947" v-model="priceFrom" label="Cena od" type="number" min="0"></v-text-field>
              <v-text-field color="#1c7947" v-model="priceTo" label="Cena do" type="number" min="0"></v-text-field>
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="#1c7947"
                    v-model="dateFrom"
                    label="Datum od"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = dateFrom"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker color="#1c7947" v-model="dateFrom" no-title @input="menu2 = false"></v-date-picker>
              </v-menu>
              <v-menu :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field color="#1c7947" v-model="dateTo" label="Datum do" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="#1c7947" v-model="dateTo" no-title @input="menu2 = false"></v-date-picker>
              </v-menu>
              <v-select color="#1c7947" v-model="PageSize" :items="[10, 20, 50]" label="Broj oglasa" hint="Broj oglasa" persistent-hint return-object single-line></v-select>
              <v-row
                ><button @click="resetFilters" class="resetButton">Poništi kriterijume</button>
                <button @click="getProducts" class="searchButton">Pretraga</button>
              </v-row>
            </v-col>
          </div>
          <div class="productWrapper">
            <button class="mobileFilterButton" @click="() => (this.isMobileFilterOpen = true)">Filteri</button>
            <div @click="goToPage(product)" v-for="product in products" :key="product.id" class="productCard">
              <img :src="product.mainImgName ? `https://api.poljopijaca.rs/${product.mainImgName}` : require('../Assets/noimg.webp')" />
              <div class="productDetails">
                <h4 class="productTitle">{{ product.title }}</h4>
                <p v-if="product.description != null">{{ product.description.slice(0, 90) + "..." }}</p>
                <p v-else>{{ descriptionNone }}</p>
                <div class="prodInfoDetails">
                  <div class="categoryTag"><v-icon>mdi-tag</v-icon> {{ product.subCategory.name.toUpperCase() }}</div>
                  <div class="priceTag">
                    <v-icon>mdi-cash</v-icon>
                    {{
                      product.price > 0
                        ? `${product.price} RSD`
                        : product.paymentOption == 1
                        ? "Dogovor"
                        : product.paymentOption == 2
                        ? "Kontakt"
                        : product.paymentOption == 3
                        ? "Pozvati"
                        : product.paymentOption == 4
                        ? "Besplatno"
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
            <p class="noProducts">
              {{ this.products.length === 0 ? "Ne postoji oglas za zadati kriterijum pretrage" : null }}
            </p>
            <v-pagination v-model="PageNumber" :length="numberOfPages" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobileFilterOpen" class="mobileFilterModalWrapper">
      <div class="mobileFilterModal">
        <v-icon class="closeFilter" @click="isMobileFilterOpen = false"> mdi-close-thick </v-icon>
        <div class="left">
          <v-col cols="12" lg="12">
            <v-select
              color="#1c7947"
              v-model="subCategoryId"
              :items="subCategories"
              item-text="name"
              item-value="id"
              label="Kategorija"
              persistent-hint
              return-object
              single-line
            ></v-select>
            <v-text-field color="#1c7947" v-model="priceFrom" label="Cena od" type="number" min="0"></v-text-field>
            <v-text-field color="#1c7947" v-model="priceTo" label="Cena do" type="number" min="0"></v-text-field>

            <v-menu :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  color="#1c7947"
                  v-model="dateFrom"
                  label="Datum od"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = dateFrom"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker color="#1c7947" v-model="dateFrom" no-title @input="menu2 = false"></v-date-picker>
            </v-menu>

            <v-menu :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="#1c7947" v-model="dateTo" label="Datum do" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker color="#1c7947" v-model="dateTo" no-title @input="menu2 = false"></v-date-picker>
            </v-menu>
            <v-select color="#1c7947" v-model="PageSize" :items="[10, 20, 50]" label="Broj oglasa" hint="Broj oglasa" persistent-hint return-object single-line></v-select>
            <v-row
              ><button @click="resetFilters" class="resetButton">Poništi kriterijume</button>
              <button @click="searchButtonHandler" class="searchButton">Pretraga</button>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import NavBar from "../Components/NavBar.vue";
import Footer from "../Components/Footer.vue";
import axios from "axios";

export default {
  name: "AddProduct",
  data() {
    return {
      messageField: "",
      subCategories: [],
      products: [],
      dateFrom: null,
      dateTo: null,
      priceFrom: null,
      priceTo: null,
      subCategoryId: null,
      locationName: null,
      PageNumber: 1,
      PageSize: 10,
      numberOfPages: null,
      isMobileFilterOpen: false,
      requestUrl: "",
      loading: false,
      descriptionNone: "Ovaj oglas nema opis.",
    };
  },

  components: {
    NavBar,
    Footer,
  },
  watch: {
    PageNumber() {
      this.getProducts();
    },
  },
  methods: {
    scroll() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getProductsFirstTime() {
      this.loading = true;
      axios
        .get(
          "Product/GetProducts?" +
            (this.dateFrom != null ? "dateFrom=" + this.dateFrom : "") +
            (this.dateTo != null ? "&dateTo=" + this.dateTo : "") +
            (this.priceFrom != null ? "&priceFrom=" + (this.priceFrom * 1 - 1) : "") +
            (this.priceTo != null ? "&priceTo=" + (this.priceTo * 1 + 1) : "") +
            (this.$route.params.subcategory > 0 ? "&subCategoryId=" + this.$route.params.subcategory : "") +
            (this.locationName != null ? "&locationName=" + this.locationName : "") +
            (this.PageNumber != null ? "&PageNumber=" + this.PageNumber : "") +
            (this.PageSize != null ? "&PageSize=" + this.PageSize : "")
        )
        .then((res) => {
          this.products = res.data.allProducts;
          this.numberOfPages = res.data.numberOfPages;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    getProducts() {
      let url =
        "Product/GetProducts?" +
        (this.dateFrom != null ? "dateFrom=" + this.dateFrom : "") +
        (this.dateTo != null ? "&dateTo=" + this.dateTo : "") +
        (this.priceFrom != null ? "&priceFrom=" + (this.priceFrom * 1 - 1) : "") +
        (this.priceTo != null ? "&priceTo=" + (this.priceTo * 1 + 1) : "") +
        (this.subCategoryId != null ? "&subCategoryId=" + this.subCategoryId?.id : "") +
        (this.locationName != null ? "&locationName=" + this.locationName : "") +
        (this.PageNumber != null ? "&PageNumber=" + this.PageNumber : "") +
        (this.PageSize != null ? "&PageSize=" + this.PageSize : "");

      if (url == this.requestUrl) {
        return;
      } else {
        this.loading = true;
        this.requestUrl = url;
      }

      axios
        .get(url)
        .then((res) => {
          this.products = res.data.allProducts;
          this.numberOfPages = res.data.numberOfPages;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    getSubcategories() {
      axios.get("Category").then((res) => {
        res.data.map((el) => {
          el.subCategories.map((el) => {
            this.subCategories.push(el);
          });
        });

        if (this.$route.params.subcategory > 0) {
          let tempObject = this.subCategories.find((x) => x.id === Number(this.$route.params.subcategory));
          this.subCategoryId = tempObject;
        }
      });
    },
    goToPage(product) {
      this.$router.push("/oglas/" + product.id);
    },
    searchButtonHandler() {
      this.getProducts();
      this.isMobileFilterOpen = false;
    },
    resetFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.priceFrom = null;
      this.priceTo = null;
      this.PageSize = 10;
      this.subCategoryId = null;
      this.getProducts();
    },
  },
  created() {
    this.getProductsFirstTime();
    this.getSubcategories();
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
.prodInfoDetails {
  i {
    color: #137547;
  }
  color: #137547;
  display: flex;
  justify-content: space-between;
}
.filter {
  margin-bottom: 0px;
  .searchField {
    margin: 5px;
  }
  .btnFilter {
    display: flex;
    justify-content: center;
  }
  .btnSearch {
    display: flex;
    background-color: #137547;
    color: whitesmoke;
  }
}

.mobileFilterButton {
  padding: 0.5rem 1rem;
  margin: 1rem;
  background-color: #1c7947;
  color: white;
}

.mobileFilterModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .mobileFilterModal {
    background-color: #ececef;
    width: 90%;
    .closeFilter {
      color: #1c7947;
      width: 100%;
      justify-content: end;
      margin: auto;
    }
    .searchButton {
      display: block;
      margin: 1rem auto;
      padding: 0.5rem 1rem;
      background-color: #1c7947;
      color: #fff;
    }

    .searchButton:hover {
      background-color: #fff;
      color: #1c7947;
    }

    .resetButton {
      display: block;
      margin: 1rem auto;
      padding: 0.5rem 1rem;
      background-color: #fff;
      border: 3px red;
      color: black;
    }

    .resetButton:hover {
      background: #f44336;
      color: white;
    }
  }
}

.searchResult {
  display: flex;
  .left {
    display: none;
    flex-direction: column;
    width: 25%;
    background-color: #ececef;
    .searchButton {
      display: block;
      margin: 1rem auto;
      padding: 0.5rem 1rem;
      background-color: #1c7947;
      color: #fff;
    }

    .searchButton:hover {
      background-color: #fff;
      color: #1c7947;
    }
    .resetButton {
      display: block;
      margin: 1rem auto;
      padding: 0.5rem 1rem;
      background-color: #fff;
      border: 3px red;
      color: black;
    }

    .resetButton:hover {
      background: #f44336;
      color: white;
    }
  }

  .productWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .productCard {
      width: 100%;
      margin: 0.25rem;
      border-radius: 4px;
      display: flex;
      max-height: 200px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      img {
        background-color: #eeeeee;
        object-fit: contain;
        width: 25%;
      }
      .productDetails {
        width: 100%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        .productTitle {
          color: #137547;
        }
        h3 {
          text-align: right;
          color: #137547;
        }
        a {
          text-decoration: none;
          color: #137547;
          transition: 0.2s ease-in-out;
          :hover {
            color: #000000cc;
          }
        }
      }
    }
    .noProducts {
      font-weight: bold;
      align-self: center;
      margin: 0 auto;
    }
  }
}

.dialogWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogWindow {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #ccc;
  margin: 1rem;
  background-color: #fff;
}

.tabWrapper {
  display: flex;
  flex-direction: column;
}

.tab {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

@media screen and (min-width: 768px) {
  .mobileFilterButton {
    display: none;
  }
  .dialogWindow {
    width: 1440px;
  }

  .dialogWindow {
    margin: 0 auto;
  }

  .searchResult {
    .left {
      display: flex;
      align-items: stretch;
    }
  }
  .mobileSearch {
    display: none;
  }
  .searchResult {
    .productWrapper {
      .productCard {
        height: 140px;
      }
    }
  }
}
</style>
